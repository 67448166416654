@import url('https://fonts.googleapis.com/css2?family=Inter&family=Source+Code+Pro&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    background-color: #0d0f11;
    color: #ffffff;
    font-family: 'Inter', sans-serif;
    h1, h2, h3, h4, h5, h6 {
        font-family: 'Source Code Pro';
        .darken {
            color: #30383f;
        }
    }
}