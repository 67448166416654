#home {
    display: flex;
    align-items: center;
    height: 100vh;
    padding: 0 100px;
    &-container {
        #title {
            font-size: 40px;
            margin-bottom: 10px;
        }
        #info {
           font-size: 19px;
            width: 400px;
            margin-bottom: 30px;
        }
        ul {
            padding-bottom: 20px;
            li {
                list-style: none;
                margin-bottom: 15px;
                display: flex;
                align-items: center;
                cursor: pointer;
                width: max-content;
                p {
                    font-size: 14px;
                    margin-left: 10px;
                }
                svg {
                    transition: color ease-in-out .2s;
                }
                &.discord {
                    p {
                        span {
                            transition: opacity ease-in-out .2s;
                            opacity: 0;
                            color: #afafaf;
                            user-select: none;
                        }
                    }
                    &:hover {
                        p {
                            span {
                                opacity: 1;
                            }
                        }
                    }
                }
                &:hover {
                    svg {
                        &#twitter {
                            color: #31aae1;
                        }
                        &#github {
                            color: #8a63d2;
                        }
                        &#discord {
                            color: #5865F2;
                        }
                        &#kofi {
                            color: #FF5E5B;   
                        }
                    }
                }
            }
        }
    }
}
